<template>
  <div class="screen">
    <div class="video-area">
      
    </div>
    <div class="control-area">
      <div class="control video" :class="videoMute?'off':'on'" @click="toggleVideo">
        <i v-if="videoMute" class="fas fa-video-slash"></i>
        <i v-else class="fas fa-video"></i>
      </div>
      <div class="control sound" :class="audioMute?'off':'on'" @click="toggleAudio">
        <i v-if="audioMute" class="fas fa-microphone-slash"></i>
        <i v-else class="fas fa-microphone"></i>
      </div>
      <div class="control screen-share" :class="screenShare?'on':''" @click="screenShareStart">
        <i class="fas fa-desktop"></i>
      </div>
      <div class="control leave-call off" @click="leaveCall">
        <i class="fas fa-phone-slash"></i>
      </div>
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import Peer from 'peerjs'
import io from 'socket.io-client'
import Noty from 'noty';
import 'noty/lib/noty.css'
import 'noty/lib/themes/mint.css'
export default {
  name: 'VideoCall',
  props: {
  },
  data: () => ({
    peers: {},
    myPeer: {},
    audioMute: true,
    videoMute: false,
    screenShare: false,
    myStreamVideo: {},
    ownScreenStream: null,
    myPeerId: ''
  }),
  mounted(){
    const _this = this;
    let ROOM_ID = "hell";

    // const node_server_url = 'http://localhost:3001/';
    const node_server_url = window.location.protocol+'//woolly-full-quasar.glitch.me';
    const socket = io(node_server_url);
    // let peerID = (Math.random()*59).toString();
    _this.myPeer = new Peer({
      // host: 'ec2-54-144-146-183.compute-1.amazonaws.com',
      // port: 9000,
      // path: 'peerjs-server',
      debug: 3, 
      config: {
        'iceServers': [
          { 
            url: 'turn:35.160.94.66:3478', 
            credential: 'password',
            username: 'admin'
          }
        ]
      }
    })
    const myVideo = document.createElement('video')
    myVideo.muted = true;
    myVideo.classList.add("own-video")
    // const peers = {}
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then(stream => {
      _this.myStreamVideo = stream;
      _this.myStreamVideo.getAudioTracks()[0].enabled = !this.audioMute;

      _this.addVideoStream(myVideo, _this.myStreamVideo)
      _this.myPeer.on('call', call => {
        
        _this.peers[call.peer] = call
        
        call.answer(_this.myStreamVideo)
        const video = document.createElement('video')
        call.on('stream', userVideoStream => {
          _this.addVideoStream(video, userVideoStream)
        })

        call.on('close', () => {
          video.remove()
          if(document.querySelectorAll('.video-area video').length == 2){
            document.querySelectorAll('.video-area video')[1].classList.add('main-video')
          }
        })
      })

      socket.on('user-connected', userId => {
        console.log('User Connected', userId)
        _this.connectToNewUser(userId, stream)
        new Noty({
            text: 'New user joined ' + userId,
            type: 'success',
            timeout: 3000
        }).show();
      })
    })


    

    socket.on('user-disconnected', userId => {
      console.log('User DisConnected', userId)
      if (_this.peers[userId]) _this.peers[userId].close()

      new Noty({
          text: 'User Disconnected ' + userId,
          type: 'warning',
          timeout: 3000
      }).show();
    })

    _this.myPeer.on('open', id => {
      _this.myPeerId = id;
      socket.emit('join-room', ROOM_ID, _this.myPeerId)
    })
    _this.myPeer.on('close', () => {
      socket.emit('leave-room', ROOM_ID, _this.myPeerId)
    })
  },
  methods: {
    connectToNewUser: function(userId, stream) {
      const _this = this;
      const call = _this.myPeer.call(userId, stream)
      const video = document.createElement('video')
      call.on('stream', userVideoStream => {
        _this.addVideoStream(video, userVideoStream)
      })
      call.on('close', () => {
        video.remove()
        if(document.querySelectorAll('.video-area video').length == 2){
          document.querySelectorAll('.video-area video')[1].classList.add('main-video')
        }
      })

      _this.peers[userId] = call

      // console.log(_this.peers, call, video)
    },

    addVideoStream: function(video, stream) {
      // const _this = this;
      const videoGrid = document.querySelector('.video-area')
      video.srcObject = stream
      video.addEventListener('loadedmetadata', () => {
        video.play()
      })

      videoGrid.append(video)
      // console.log(document.querySelectorAll('.video-area video').length, 'video')
      if(document.querySelectorAll('.video-area video').length == 2){

        video.classList.add('main-video')
      } else if(document.querySelectorAll('.video-area video').length > 2 && document.querySelectorAll('.main-video').length != 0){
        document.querySelector('.main-video').classList.remove('main-video')
      }

    },

    toggleVideo: function(){
      this.videoMute = !this.videoMute
      this.myStreamVideo.getVideoTracks()[0].enabled = !this.videoMute;
      new Noty({
          text: `Video turn ${this.videoMute?'off':'on'} successfully`,
          type: 'info',
          timeout: 3000
      }).show();
    },

    toggleAudio: function(){
      this.audioMute = !this.audioMute
      this.myStreamVideo.getAudioTracks()[0].enabled = !this.audioMute;
      new Noty({
          text: `Audio turn ${this.audioMute?'off':'on'} successfully`,
          type: 'info',
          timeout: 3000
      }).show();
    },

    leaveCall: function(){
      if(this.myPeer._open){
        this.myPeer.destroy();
        new Noty({
            text: 'You leaved from the video call successfully, to join again - please refresh the page',
            type: 'info',
            timeout: 10000
        }).show();
      } else {
        new Noty({
            text: 'You are not connected to video call room any more, to join again - please refresh the page',
            type: 'error',
            timeout: 10000  
        }).show();
      }
    },
    getOwnScreenStream: async function(callback){
      const _this = this;
      try{

        let screenStream = null;

        screenStream = await navigator.mediaDevices.getDisplayMedia();

        if(screenStream != null){
          _this.ownScreenStream = screenStream
        } else {
          _this.ownScreenStream = null;
        }

        callback()
      } catch(err){
        _this.$snotify.error(
            err,
            'Error',{
              timeout: 500
            }
          )
        callback()
      }
    },

    screenShareStart: async function(){
      const _this = this;
      this.getOwnScreenStream(function(){
        if(_this.ownScreenStream != null){
          Object.keys(_this.peers).forEach((peer) => {
            _this.myPeer.call(peer, _this.ownScreenStream);
          });
          
        }
      })

      // new Noty({
      //     text: 'Screen share not ready yet',
      //     type: 'error',
      //     timeout: 3000
      // }).show();
      
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.screen{
  width: 100%;
  position: relative;
}
.screen .control-area{
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  display: flex;
  justify-content: center;
}
.screen .control-area .control{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #777;
  cursor: pointer;
  opacity: .8;
  transition: .3s
}
.screen .control-area .control.on{
  background: green;
}
.screen .control-area .control.off{
  background: red;
}
.screen .control-area .control:hover{
  opacity: 1
}
.screen .control-area .control i, .screen .control-area .control svg{
  color: #fff
}
.video-area{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #000;
  width: 100%;
  min-height: 100vh;
}
.video-area video{
  width: 300px;
  height: 300px;
  margin: 10px;
  object-fit: cover;
  background: #343434;
}
.video-area video.own-video{
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: fixed;
  left: 15px;
  bottom: 15px;
  box-shadow: 0 0 5px 0px #bcb7b7;
}
.video-area video.main-video{
  height: calc(100vh - 2*10px);
  width: 100%;
  object-fit: contain;
}

body{
  margin: 0;
}
</style>
